<script setup>
import { ref, onMounted, watch, computed } from "vue";

const props = defineProps(["min", "max", 'selectedMin', 'selectedMax']);

const emit = defineEmits(["onChange"]);

const range = ref(props.max - props.min);

const leftSlider = ref(null);
const leftSliderPos = computed(() => minValue.value - props.min);

const rightSlider = ref(null);
const rightSliderPos = computed(() => maxValue.value - props.min);

const minValue = ref(props.selectedMin ? props.selectedMin : props.min);
const maxValue = ref(props.selectedMax ? props.selectedMax : props.max);

const sliderColor = ref("#c7c7c7");
const rangeColor = ref("#000");

onMounted(() => {
  leftSlider.value.oninput = (event) => {
    const value = +event.target.value;

    if (value <= maxValue.value) {
      minValue.value = value;
      leftSlider.value = value;
    } else {
      minValue.value = maxValue.value;
      leftSlider.value.value = maxValue.value;
    }
  };

  rightSlider.value.oninput = (event) => {
    const value = +event.target.value;

    if (value === props.min) {
      rightSlider.value.style.zIndex = 2;
    } else {
      rightSlider.value.style.zIndex = 0;
    }

    if (value > minValue.value) {
      maxValue.value = value;
      rightSlider.value.value = value;
    } else {
      maxValue.value = minValue.value;
      rightSlider.value.value = minValue.value;
    }
  };
});

watch([minValue, maxValue], () => {
  emit("onChange", minValue.value, maxValue.value);
});
</script>

<!-- aria-label and aria-valuetext should probably be passed as props to make dualSlider generic -->

<template>
  <div class="flex flex-col w-60 gap-14">
    <div class="flex justify-between items-center">
      <span  class="text-lg font-light border border-black py-1 px-6" >{{ minValue }}</span>
      <span class="w-4 h-px bg-black/60"></span>
      <span class="text-lg font-light border border-black py-1 px-6">{{ maxValue }}</span>
    </div>

    <div class="relative">
      <input
        ref="leftSlider"
        id="fromSlider"
        type="range"
        role="slider"
        :value="minValue"
        :min="props.min"
        :max="props.max"
        :aria-valuenow="minValue"
        :aria-valuemin="props.min"
        :aria-valuemax="props.max"
        :aria-valuetext="`Mindestalter: ${minValue}`"
        aria-label="Mindesalter"
      />
      <input
        ref="rightSlider"
        id="toSlider"
        type="range"
        role="slider"
        :value="maxValue"
        :min="props.min"
        :max="props.max"
        :aria-valuenow="maxValue"
        :aria-valuemin="props.min"
        :aria-valuemax="props.max"
        :aria-valuetext="`Höchstalter: ${maxValue}`"
        aria-label="Höchstalter"
        :style="{
          background: `linear-gradient(
      to right,
      ${sliderColor} 0%,
      ${sliderColor} ${(leftSliderPos / range) * 100}%,
      ${rangeColor} ${(leftSliderPos / range) * 100}%,
      ${rangeColor} ${(rightSliderPos / range) * 100}%, 
      ${sliderColor} ${(rightSliderPos / range) * 100}%, 
      ${sliderColor} 100%)`,
        }"
      />
    </div>
  </div>
</template>

<style>

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  pointer-events: all;
  width: 24px;
  height: 24px;
  background-color: #000;
  border-radius: 50%;
  box-shadow: 0 0 0 1px #c6c6c6;
  cursor: pointer;
}

input[type="range"]::-moz-range-thumb {
  -webkit-appearance: none;
  pointer-events: all;
  width: 24px;
  height: 24px;
  background-color: #000;
  border-radius: 50%;
  box-shadow: 0 0 0 1px #c6c6c6;
  cursor: pointer;
}

input[type="range"]::-webkit-slider-thumb:hover {
  background: #f7f7f7;
}

input[type="range"]::-webkit-slider-thumb:active {
  box-shadow: inset 0 0 3px #387bbe, 0 0 9px #387bbe;
  -webkit-box-shadow: inset 0 0 3px #387bbe, 0 0 9px #387bbe;
}

input[type="range"] {
  -webkit-appearance: none;
  appearance: none;
  height: 2px;
  width: 100%;
  position: absolute;
  background-color: #c6c6c6;
  pointer-events: none;
}

#fromSlider {
  height: 0;
  z-index: 1;
}
</style>
