<script setup>
const props = defineProps(["selectedOptions"]);
const emit = defineEmits(["remove"]);

import t from "../translations";

function onClick(option) {
  emit("remove", option);
}
</script>

<template>
  <button
    v-for="option in props.selectedOptions"
    :key="option"
    class="flex gap-2 items-center bg-gray w-fit py-2 px-4 rounded-3xl cursor-pointer"
    @click="onClick(option)"
    :aria-label="`Gefilterte Option ${option} entfernen`"
    aria-relevant="removals"
  >
    <p>
      {{ t(option) }}
    </p>

    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
    >
      <path d="M1 1L9 9M9 1L1 9" stroke="black" stroke-linecap="round" />
    </svg>
  </button>
</template>
