<script setup>
import { ref, watch } from "vue";

import {
  Listbox,
  ListboxButton,
  ListboxOptions,
  ListboxOption,
} from "@headlessui/vue";

import t from "../translations";

// items: string[]
const props = defineProps(["items", "selectedItems", "title"]);
const emit = defineEmits(["change"]);

const selectedItems = ref(props.selectedItems);

watch(selectedItems, () => {
  emit("change", selectedItems);
});
</script>

<template>
  <div class="lg:relative">
    <Listbox
      :modelValue="props.selectedItems"
      @update:modelValue="
        (newSelectedItems) => (selectedItems = newSelectedItems)
      "
      multiple
      v-slot="{ open }"
    >
      <ListboxButton
        class="flex items-center gap-2"
        :aria-label="`Filter nach ${props.title}`"
      >
        <p class="">{{ props.title }}</p>
        <svg
          :class="[open ? 'rotate-180' : '']"
          class="scale-75"
          width="20"
          height="14"
          viewBox="0 0 20 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          aria-hidden="true"
        >
          <path
            d="M1 1L10 11.8L19 1"
            stroke="black"
            stroke-width="2"
            stroke-linecap="round"
          />
        </svg>
      </ListboxButton>

      <ListboxOptions
        class="absolute top-[145%] lg:top-[170%] left-0 flex flex-col divide-y divide-black/10 bg-white z-50 _shadow max-h-72 overflow-y-auto overflow-x-hidden"
      >
        <ListboxOption
          v-for="item in props.items"
          :key="item"
          :value="item"
          v-slot="{ active, selected }"
          class="w-full mr-10 whitespace-nowrap"
        >
          <div
            class="flex items-center gap-2 px-6 py-3 cursor-pointer"
            :class="[active ? 'bg-blue text-white' : 'bg-white text-black']"
          >
            <svg
              class="shrink-0"
              :class="[selected ? 'visible' : 'invisible']"
              stroke="black"
              fill="black"
              stroke-width="1"
              viewBox="0 0 16 16"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"
              ></path>
            </svg>

            <p>
              {{ t(item) }}
            </p>
          </div>
        </ListboxOption>
      </ListboxOptions>
    </Listbox>
  </div>
</template>
