<script setup>
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/vue";
import DualSlider from "./DualSlider.vue";

const props = defineProps(['min', 'max', 'selectedMin', 'selectedMax', 'title'])
const emit = defineEmits(['onChange'])

function onChange(min, max) {
    emit('onChange', min, max)
}

</script>

<template>
  <Popover class="lg:relative" v-slot="{ open }">
    <PopoverButton class="flex items-center gap-2" aria-label="Filter nach Alter">
      <p class="">{{title}}</p>
      <svg
        :class="[open ? 'rotate-180' : '']"
        class="scale-75"
        width="20"
        height="14"
        viewBox="0 0 20 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        aria-hidden="true"
      >
        <path
          d="M1 1L10 11.8L19 1"
          stroke="black"
          stroke-width="2"
          stroke-linecap="round"
        />
      </svg>
    </PopoverButton>

    <PopoverPanel class="absolute top-[145%] lg:top-[170%] left-0  flex flex-col gap-4 bg-white z-50 _shadow px-6  py-12">
      <DualSlider :min="props.min" :max="props.max" :selectedMin="props.selectedMin" :selectedMax="props.selectedMax" @onChange="onChange"/>
    </PopoverPanel>
  </Popover>
</template>
