import "../css/main.css";

import { createApp } from "vue/dist/vue.esm-bundler.js";

import MarqueeText from "vue-marquee-text-component";
import {
  Popover,
  PopoverButton,
  PopoverPanel,
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from "@headlessui/vue";
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";
import { Navigation, Pagination } from "swiper";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

import LanguagePicker from "../vue/language-picker/index.vue";
import Institutions from "../vue/institutions/Institutions.vue";
import ScrollTo from "../vue/btn/ScrollTo.vue";
import Tooltip from "../vue/btn/Tooltip.vue";
import Logo from "../vue/svgs/Logo.vue";
import Banner from "../vue/marquee/Banner.vue";

import AllowAdvertisingCookiesBtn from "../vue/cookie-first/AllowAdvertisingCookiesBtn.vue";

const app = createApp({
  components: {
    LanguagePicker,
    Popover,
    PopoverButton,
    PopoverPanel,
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    MarqueeText,
    Swiper,
    SwiperSlide,
    Institutions,
    ScrollTo,
    Tooltip,
    Logo,
    Banner,
    AllowAdvertisingCookiesBtn,
  },
  data() {
    return {
      navMenuOpen: false,
      modules: [Navigation, Pagination],
    };
  },
  methods: {
    toggleNavMenu() {
      this.navMenuOpen = !this.navMenuOpen;
    },
  },
  compilerOptions: {
    delimiters: ["${", "}"],
  },
  mounted() {
    // quick fix for search site, where autofocus is not working as expected
    const input = document.querySelector(".autofocus");
    if (input) {
      input.focus({ preventScroll: true });
    }
  },
}).mount("#app");
