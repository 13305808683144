<!-- Any new content that appears or is operable via hover should also trigger via the keyboard. Common failures of keyboard equivalence include exposing label or help information only via tooltips, or not providing a keyboard method (normally pressing the Escape key) to dismiss the new content. -->

<script setup>
import { ref, onMounted, onBeforeUnmount } from "vue";

const props = defineProps(["tooltipText"]);

const btn = ref(null)

const isOpen = ref(false);

function open() {
  isOpen.value = true;
}

function close() {
  isOpen.value = false;
}

function onKeydown(event) {
  if (event.key === "Escape") {
    close();
  }
}

onMounted(() => {
  window.addEventListener("keydown", onKeydown);
});

onBeforeUnmount(() => {
  window.removeEventListener("keydown", onKeydown);
});
</script>

<template>
  <button
    type="button"
    role="tooltip"
    @click="open"
    @focus="open"
    @blur="close"
    @mouseover="open"
    @mouseleave="close"
    class="relative"
    :aria-label="props.tooltipText"
    ref="btn"
  >
    <slot></slot>

    <div aria-hidden class="absolute bg-white p-2 shadow" :class="[isOpen ? 'block' : 'hidden']">
      {{ props.tooltipText }}
    </div>
  </button>
</template>
