export default function t(word) {
  // craftTranslations will be set by the twig plugin craft-javascript-helpers as a global variable

  if (typeof craftTranslations !== "undefined" && craftTranslations) {
    if (craftTranslations.hasOwnProperty(word)) {
      return craftTranslations[word];
    }
  }

  return word;
}
