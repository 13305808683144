<script setup>
import { ref, onMounted } from "vue";

const props = defineProps(["elementId", "yOffset", "ariaLabel"]);

const elementYPosition = ref(null);

onMounted(() => {
  // scrool smoothly to element container but with a Y offset
  const yOffset = props.yOffset ?? -10;
  const element = document.getElementById(props.elementId);
  if (element) {
    elementYPosition.value =
      element.getBoundingClientRect().top + window.pageYOffset + yOffset;
  }
});

function onClick() {
  if (elementYPosition.value) {
    window.scrollTo({ top: elementYPosition.value, behavior: "smooth" });
  }
}
</script>

<template>
  <button @click="onClick" :aria-label="props.ariaLabel">
    <slot></slot>
  </button>
</template>
