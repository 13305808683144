<script setup>
const props = defineProps(["class"]);

const defaultStroke =
  props.class && props.class.includes("stroke") ? "" : "stroke-white";
</script>

<template>
  <svg
    :class="[defaultStroke, props.class]"
    width="18"
    height="12"
    viewBox="0 0 18 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2 2L9 9.4975L16 2"
      stroke-width="2.49917"
      stroke-linecap="round"
    />
  </svg>
</template>
