<script setup>
import t from "../translations";

function onAccept() {
  CookieFirst.acceptCategory("advertising");
}

function onDeny() {
  CookieFirst.declineCategory("advertising");
  // for some reason the page won't get refreshed => so, do it manually
  location.reload();
}
</script>

<template>
  <div class="flex flex-col items-center gap-4 text-xl">
    <p class="">
      {{ t("Bitte Marketing-Cookies akzeptieren um diese Karte zu nutzen.") }}
    </p>

    <div class="flex gap-4">
      <button
        @click="onAccept"
        class="px-2 py-2 bg-white rounded text-dark-blue"
      >
        {{ t("Ja, Karte aktivieren.") }}
      </button>

      <button @click="onDeny" class="px-2 py-2 bg-white rounded text-dark-blue">
        {{ t("Nein, nur eine Vorschau anzeigen.") }}
      </button>
    </div>
  </div>
</template>
