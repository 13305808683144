<script setup>
import { ref } from "vue";
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/vue";

import Chevron from "../svgs/Chevron.vue";

// languages {title: str, url: str, countryCode: str, currentIndicator: bool, easyLang: str}[]
const props = defineProps(["languages", "hasDarkBackground", "positionMenu"]);

const currentlySelectedLang = ref(
  props.languages.filter((l) => l.currentIndicator)[0]
);
const languages = props.languages.filter((l) => !l.currentIndicator);

const textColor = props.hasDarkBackground ? "text-white" : "text-black";
const bgColor = props.hasDarkBackground ? "bg-dark-blue" : "bg-white";
const strokeColor = props.hasDarkBackground ? "stroke-white" : "stroke-black";
const ringColor = props.hasDarkBackground ? "ring-white" : "ring-black";
const positionMenu = props.positionMenu
  ? props.positionMenu
  : "bottom-[133%] left-1/2 -translate-x-1/2 transform";
</script>

<template>
  <Popover
    v-if="languages.length > 0"
    v-slot="{ open }"
    class="relative"
    :class="textColor"
    aria-label="Sprachauswahl"
  >
    <PopoverButton
      :class="[open ? '' : 'text-opacity-90', ringColor]"
      class="group inline-flex items-center px-3 py-2 text-base font-medium hover:text-opacity-100 hover:ring-2 focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75"
      :aria-label="`Ausgewählte Sprache: ${currentlySelectedLang.title}`"
    >
      <span class="uppercase">{{ currentlySelectedLang.countryCode }}</span>
      <span
        :class="open ? 'rotate-180' : 'text-opacity-70'"
        class="ms-2 transition duration-150 ease-in-out group-hover:text-opacity-80"
        aria-hidden="true"
        tabindex="-1"
      >
        <chevron :class="strokeColor" />
      </span>
    </PopoverButton>

    <transition
      enter-active-class="transition duration-200 ease-out"
      enter-from-class="translate-y-1 opacity-0"
      enter-to-class="translate-y-0 opacity-100"
      leave-active-class="transition duration-150 ease-in"
      leave-from-class="translate-y-0 opacity-100"
      leave-to-class="translate-y-1 opacity-0"
    >
      <PopoverPanel
        class="absolute z-50 mt-3 w-screen max-w-sm sm:px-0"
        :class="[positionMenu, bgColor]"
      >
        <div
          class="overflow-hidden shadow-lg ring-1 ring-opacity-5"
          :class="ringColor"
        >
          <ol class="relative border-l border-white px-7 py-6">
            <li class="grid gap-8">
              <a
                v-for="item in languages"
                :key="item.title"
                :href="item.url"
                class="-m-3 flex items-center rounded-lg p-2 transition duration-150 ease-in-out hover:bg-gray-50 hover:ring focus:outline-none focus-visible:ring focus-visible:ring-opacity-50"
                :class="ringColor"
                :aria-label="`Wähle ${
                  item.easyLang !== '' ? item.easyLang : item.title
                } als Sprache aus`"
              >
                <p class="uppercase ml-4">
                  {{ item.easyLang !== "" ? item.easyLang : item.countryCode }}
                </p>
              </a>
            </li>
          </ol>
        </div>
      </PopoverPanel>
    </transition>
  </Popover>
</template>
