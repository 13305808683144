<script setup>
import { ref, watch, onMounted } from "vue";

import FilterListbox from "../form/FilterListbox.vue";
import SelectedFilterOptions from "../form/SelectedFilterOptions.vue";
import AgeFilter from "../form/AgeFilter.vue";

const props = defineProps([
  "institutions", "category",
  "filterintro",
  "filtercategorie",
  "filterdistrict",
  "filterage",
  "filteractivities",
  "filteraccessability",
  "filterselected",
  "labelinstitutions",
]);

const institutions = ref(props.institutions);

// TODO get from data
const minAge = 0;
const maxAge = 27;

// collect unique institutionTypes
const types = new Set();
// collect unique districts
const districts = new Set();
// collect unique activities
const activities = new Set();
// collect unique a11y hints
const a11yHints = new Set();

props.institutions.forEach((institution) => {
  types.add(institution.type);

  institution.activities.forEach((activity) => {
    activities.add(activity);
  });

  institution.a11yHints.forEach((a11yHint) => {
    a11yHints.add(a11yHint);
  });

  districts.add(institution.district);
});

const selectedTypes = ref(props.category ? [props.category] : []);
const selectedMinAge = ref(minAge);
const selectedMaxAge = ref(maxAge);
const selectedDistricts = ref([]);
const selectedActivities = ref([]);
const selectedA11yHints = ref([]);

// TODO replace with inline function
function onTypesChange(selected) {
  selectedTypes.value = selected.value;
}
// TODO replace with inline function
function onDistrictChange(selected) {
  selectedDistricts.value = selected.value;
}
// TODO replace with inline function
function onActivitiesChange(selected) {
  selectedActivities.value = selected.value;
}
// TODO replace with inline function
function onA11yHintsChange(selected) {
  selectedA11yHints.value = selected.value;
}

function onAgeSliderChange(min, max) {
  selectedMinAge.value = min;
  selectedMaxAge.value = max;
}

const typesFilter = (institution) => {
  // empty types filter => dont filter institutions
  if (selectedTypes.value.length === 0) {
    return true;
  }

  // check if institution type in selectedTypes
  if (selectedTypes.value.includes(institution.type)) {
    return true;
  }

  return false;
};

const ageFilter = (institution) => {
  // nothing selected => dont filter
  if (selectedMinAge.value === minAge && selectedMaxAge.value === maxAge) {
    return true;
  }

  for (const ageGroup of institution.ageGroup) {
    const [institutionMinAge, institutionMaxAge] = getMinMaxAge(ageGroup);

    if (institutionMinAge && institutionMaxAge) {
      if (
        (institutionMinAge >= selectedMinAge.value &&
          institutionMinAge <= selectedMaxAge.value) ||
        (institutionMaxAge >= selectedMinAge.value &&
          institutionMaxAge <= selectedMaxAge.value)
      ) {
        return true;
      }
    }
  }

  return false;
};

const activitiesFilter = (institution) => {
  // nothing selected => dont filter
  if (selectedActivities.value.length === 0) {
    return true;
  }

  // check if institution activity in selectedActivities
  for (const activity of institution.activities) {
    if (selectedActivities.value.includes(activity)) {
      return true;
    }
  }

  return false;
};

const districtFilter = (institution) => {
  // nothing selected => dont filter
  if (selectedDistricts.value.length === 0) {
    return true;
  }

  // check if institution ageGroup in selectedAgeGroups
  return selectedDistricts.value.includes(institution.district);
};

const a11yHintsFilter = (institution) => {
  // nothing selected => dont filter
  if (selectedA11yHints.value.length === 0) {
    return true;
  }

  // check if institution a11yHints in selectedA11yHints
  // ie check if the institution meets the selected a11y criteria
  for (const a11yHint of institution.a11yHints) {
    if (selectedA11yHints.value.includes(a11yHint)) {
      return true;
    }
  }

  return false;
};

// Helper function to extract age from ageGroup
function getMinMaxAge(ageGroup) {
  // assuming ageGroup is in this format: age - age Jahre
  const minRegex = /[0-9]*(?= -)/;
  const maxRegex = /(?<=- )[0-9]*/;

  const minResult = minRegex.exec(ageGroup);
  const maxResult = maxRegex.exec(ageGroup);

  if (minResult && maxResult) {
    return [minResult[0], maxResult[0]];
  }

  return [null, null];
}

function remove(item, from) {
  return from.filter((i) => i !== item);
}

function onChange() {
  institutions.value = props.institutions
    .filter(typesFilter)
    .filter(ageFilter)
    .filter(districtFilter)
    .filter(a11yHintsFilter)
    .filter(activitiesFilter);
}

watch(
  [
    selectedTypes,
    selectedMinAge,
    selectedMaxAge,
    selectedDistricts,
    selectedActivities,
    selectedA11yHints,
  ],
  onChange
);

onMounted(onChange);
</script>

<template>
  <div class="container my-16 px-3">
    <!-- filter -->
    <div class="my-8 lg:mb-8">
      <div class="flex flex-col gap-4">
        <div class="flex flex-col lg:flex-row gap-8">
          <p class="font-medium">{{filterintro}}</p>

          <div class="grid grid-cols-2 lg:flex gap-8 relative">
            <!-- wieso kann ich hier keine inline funktion benutzen? -->
            <!-- <FilterListbox title="Types" :items="types" @change="(selected) => (selectedTypes.value = selected.value)" /> -->
            <FilterListbox
              :title="filtercategorie"
              :items="types"
              :selectedItems="selectedTypes"
              @change="onTypesChange"
            />

            <FilterListbox
              :title="filterdistrict"
              :items="districts"
              :selectedItems="selectedDistricts"
              @change="onDistrictChange"
            />

            <AgeFilter
              :title="filterage"
              :min="minAge"
              :max="maxAge"
              :selectedMin="selectedMinAge"
              :selectedMax="selectedMaxAge"
              @onChange="onAgeSliderChange"
            />

            <FilterListbox
              :title="filteractivities"
              :items="activities"
              :selectedItems="selectedActivities"
              @change="onActivitiesChange"
            />

            <FilterListbox
              :title="filteraccessability"
              :items="a11yHints"
              :selectedItems="selectedA11yHints"
              @change="onA11yHintsChange"
            />
          </div>

          <div class="h-0.5 w-full bg-gray lg:hidden" />
        </div>

        <div class="h-0.5 w-full bg-gray hidden lg:block" />
      </div>
    </div>

    <!-- selected FilterOptions -->
    <ul
      class="my-8 flex flex-wrap gap-4"
      :aria-label="filterselected"
      aria-relevant="removals"
    >
      <SelectedFilterOptions
        :selectedOptions="selectedTypes"
        @remove="(type) => (selectedTypes = remove(type, selectedTypes))"
      />

      <SelectedFilterOptions
        :selectedOptions="selectedDistricts"
        @remove="
          (district) =>
            (selectedDistricts = remove(district, selectedDistricts))
        "
      />

      <SelectedFilterOptions
        :selectedOptions="
          selectedMinAge !== minAge ? [`Ab ${selectedMinAge} Jahre`] : undefined
        "
        @remove="() => (selectedMinAge = minAge)"
      />

      <SelectedFilterOptions
        :selectedOptions="
          selectedMaxAge !== maxAge
            ? [`Bis ${selectedMaxAge} Jahre`]
            : undefined
        "
        @remove="() => (selectedMaxAge = maxAge)"
      />

      <SelectedFilterOptions
        :selectedOptions="selectedActivities"
        @remove="
          (activity) =>
            (selectedActivities = remove(activity, selectedActivities))
        "
      />

      <SelectedFilterOptions
        :selectedOptions="selectedA11yHints"
        @remove="
          (a11yHint) =>
            (selectedA11yHints = remove(a11yHint, selectedA11yHints))
        "
      />
    </ul>

    <!-- institutions -->
    <div class="sr-only" aria-live="polite" aria-atomic="true" id="searchResults">
      {{institutions.length }} {{labelinstitutions}}
    </div>

    <slot :institutions="institutions"></slot>

    <!-- pagination -->
    <div></div>
  </div>
</template>
